<template>
  <div class="mindgame-interaction" :class="{ isDebug: isDebug }">
    <div class="mindgame-interaction__container">
      <div class="mindgame-interaction__progress">
        <span>{{ step + 1 }} | {{ contents.length }}</span>
        <div class="mindgame-interaction__progress-fill">
          <div ref="fillrect" class="rect"></div>
        </div>
      </div>

      <div class="mindgame-interaction__step" ref="steps">
        <template v-if="!testComplete">
          <h2 v-html="currentContent.headline"></h2>
          <h3 v-html="currentContent.subline"></h3>
        </template>

        <template v-else-if="testComplete">
          <h2>Test Complete</h2>
          <h3>Your results:</h3>
        </template>

        <div class="mindgame-interaction__step-container" v-if="step === 0">
          <input
            type="number"
            id="age"
            ref="age_input"
            placeholder="Insert your age"
            @input="checkAge"
          />
        </div>

        <div
          class="mindgame-interaction__step-container"
          v-else-if="!testComplete"
        >
          <div
            v-if="step === 1"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                <img
                  :src="
                    '/assets/img/mindgame/image1_' + (index + 1) + '@2x.jpg'
                  "
                  alt=""
                />
                <span>{{ item.value }}</span>
                <div class="hiddenvalue">{{ item.score }}</div>
                <div class="color-blender"></div>
              </li>
            </ul>
          </div>

          <div
            v-else-if="step === 2"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <div class="mindgame-interaction__choice-container">
              <div style="color: #386b7e">RED</div>
              <div style="color: #453492">PURPLE</div>
              <div style="color: #df98c0">VIOLETT</div>
              <div style="color: #02a7df">BLUE</div>
              <div style="color: #cd322d">GREEN</div>
              <div style="color: #f9c436">YELLOW</div>
              <div style="color: #bec7d6">GREY</div>
              <div style="color: #45bfb0">WHITE</div>
            </div>

            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                {{ item.value }}
                <div class="hiddenvalue">{{ item.score }}</div>
              </li>
            </ul>
          </div>

          <div
            v-else-if="step === 3"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <div class="mindgame-interaction__choice-container">
              <video muted playsinline autoplay loop ref="video">
                <source src="assets/videos/680-couples.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                <div class="hiddenvalue">{{ item.score }}</div>
                {{ item.addition }}
              </li>
            </ul>
          </div>

          <div
            v-else-if="step === 4"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <div
              class="
                mindgame-interaction__choice-container
                mindgame-interaction__choice-quote
              "
            >
              "Which witch switched the Swiss wristwatches?"
            </div>

            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                <div class="hiddenvalue">{{ item.score }}</div>
                {{ item.addition }}
              </li>
            </ul>
          </div>

          <div
            v-else-if="step === 5"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                <div class="hiddenvalue">{{ item.score }}</div>
                {{ item.value }}
              </li>
            </ul>
          </div>

          <div
            v-else-if="step === 6"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <div class="mindgame-interaction__choice-container">
              <img src="/assets/img/mindgame/image3_1.png" alt="" />
            </div>
            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                <div class="hiddenvalue">{{ item.score }}</div>
                <img
                  :src="'/assets/img/mindgame/form-' + item.addition + '.png'"
                  alt=""
                  v-if="item.addition"
                />
                <template v-else>{{ item.value }}</template>
              </li>
            </ul>
          </div>

          <div
            v-else-if="step === 7"
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <div class="mindgame-interaction__choice-container">
              <img src="/assets/img/mindgame/image4_1.jpg" alt="" />
            </div>
            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                <div class="hiddenvalue">{{ item.score }}</div>
                {{ item.value }}
              </li>
            </ul>
          </div>

          <div
            v-else
            class="mindgame-interaction__choice"
            :class="{ ['mindgame-interaction__choice--' + step]: true }"
          >
            <ul>
              <li
                v-for="(item, index) in contents[step].choices"
                :key="index"
                @click="setAnswer(item, index)"
                :class="{ active: currentAnwerIndex === index }"
              >
                {{ item }}
                <span>{{ item.value }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="mindgame-interaction__step-container">
          <div class="mindgame-interaction__complete" v-if="testComplete">
            <p>
              Regarding your mental fitness our test results give an estimated
              age of {{ ageCognitive }}. At the same time, the
              emotional-spiritual maturity calculated for you corresponds to an
              age of {{ ageMental }} years. What does this mean?
            </p>

            <p>
              {{ cognitiveText }}
            </p>

            <p>
              {{ mentalText }}
            </p>
            <template v-if="isDebug">
              <br /><br />
              <div>
                <p v-for="(item, index) in answers" :key="index">
                  Answer {{ index + 1 }}: {{ item }}
                </p>
              </div>

              <strong>AGE: </strong
              >{{ Math.max(answers[0].age, 25) }} (ORIGINAL:
              {{ this.answers[0].age }})<br /><br />
              <strong>MENTAL SCORE:</strong> {{ scores[0] }}<br />

              <strong>COGNITIVE SCORE:</strong> {{ scores[1] }}<br />

              <strong>MENTAL AGE: </strong> {{ ageMental }} (ORIGINAL:{{
                (age * 2 + scores[0]) / 2
              }})<br />
              <strong>COGNITIVE AGE: </strong> {{ ageCognitive }} (ORIGINAL:{{
                (age * 2 + scores[1]) / 2
              }})<br />
              <br />
              MENTAL AGE = (AGE * 2 + MENTAL_SCORE) / 2<br />
              COGNITIVE AGE = (AGE * 2 + COGNITIVE_SCORE) / 2
            </template>
          </div>
        </div>
      </div>

      <div class="mindgame-interaction__buttons">
        <Button
          class="button--small"
          :disabled="buttonDisabled"
          @click.native="onNextClick"
          v-if="!testComplete"
          ref="next"
          >NEXT</Button
        >
        <template v-else>
          <Button
            class="button--small"
            @click.native="onRestartClick"
            ref="restart"
            >RESTART</Button
          >
          <Button class="button--small" @click.native="onExitClick" ref="exit"
            >EXIT</Button
          >
        </template>

        <!--<Button class="button--small" @click.native="onPreviousClick" ref="next" v-if="step > 0">PREVIOUS</Button>
      <Button class="button--small" @click.native="onNextClick" ref="prev" v-if="step < contents.length-1">NEXT</Button>-->
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";
import Button from "../../../components/form/Button";

export default {
  name: "MindgameInteraction",
  mixins: [DownloadHelperMixin],
  components: { Button },
  data() {
    return {
      isDebug: false,
      scores: [0, 0],
      testComplete: false,
      currentAnwerIndex: undefined,
      answered: false,
      ageValid: false,
      buttonDisabled: true,
      step: 0,
      answers: [],
      ageMental: 0,
      ageCognitive: 0,
      age: 0,
      mentalText: "",
      cognitiveText: "",
      contents: [
        {
          id: "1",
          headline: "First of all, please tell us:<br>How old are you?",
          subline: "(Your physical age)"
        },
        {
          id: "2",
          headline: "Which activities would you spontaneously like to do? ",
          subline: "Choose the fitting images",
          category: 0,
          choices: [
            {
              value: "Sightseeing",
              addition: "A",
              score: 5
            },
            {
              value: "Pillow fight",
              addition: "B",
              score: -6
            },
            {
              value: "Climbing",
              addition: "C",
              score: -4
            },
            {
              value: "Shopping",
              addition: "D",
              score: -2
            },
            {
              value: "Mini golf",
              addition: "E",
              score: 3
            },
            {
              value: "Going out",
              addition: "F",
              score: -3
            },
            {
              value: "Reading a book",
              addition: "G",
              score: +6
            }
          ]
        },
        {
          id: "3",
          headline: "How many words are written in the correct color? ",
          subline: "Choose the right number",
          category: 1,
          choices: [
            {
              value: "1",
              addition: "",
              score: 5
            },
            {
              value: "2",
              addition: "",
              score: 3
            },
            {
              value: "3",
              addition: "",
              score: 1
            },
            {
              value: "4",
              addition: "",
              score: -5
            },
            {
              value: "5",
              addition: "",
              score: 1
            }
          ]
        },

        {
          id: "4",
          headline: "4. How many couples are shown here? ",
          subline: "Choose the right number ",
          category: 1,
          choices: [
            {
              value: "A",
              addition: "6",
              score: 5
            },
            {
              value: "B",
              addition: "9",
              score: -5
            },
            {
              value: "C",
              addition: "12",
              score: 2
            }
          ]
        },
        {
          id: "5",
          headline: "Say this tongue-twister 3 times in a row. ",
          subline:
            "Did you manage to say it without any mistakes? (Please be honest) ",
          category: 1,
          choices: [
            {
              value: "A",
              addition: "No Mistakes",
              score: -3
            },
            {
              value: "B",
              addition: "Some Mistakes",
              score: 0
            },
            {
              value: "C",
              addition: "Failed badly",
              score: 3
            }
          ]
        },
        {
          id: "6",
          headline: "Meeting new people for the first time ...? ",
          subline: "Complete the sentence",
          category: 0,
          choices: [
            {
              value: "I approach them directly and open-minded.",
              addition: "A",
              score: 4
            },
            {
              value: "Probably I'd act not completely unprejudiced, I guess.",
              addition: "B",
              score: -4
            },
            {
              value: "Most likely I am a little reserved at first.",
              addition: "C",
              score: -2
            },
            {
              value:
                "I've been disappointed too often. I'd react somewhat suspicious.",
              addition: "D",
              score: -6
            },
            {
              value:
                "My reaction always depends on how likeable someone is to me.",
              addition: "E",
              score: 6
            }
          ]
        },
        {
          id: "7",
          headline: "Which shape is missing here?",
          subline: "Choose the fitting one",
          category: 1,
          choices: [
            {
              value: "Form A",
              addition: "a",
              score: -1
            },
            {
              value: "Form B",
              addition: "b",
              score: 4
            },
            {
              value: "Form C",
              addition: "c",
              score: -1
            },
            {
              value: "Form D",
              addition: "d",
              score: -5
            },
            {
              value: "None of them",
              addition: "",
              score: 3
            }
          ]
        },
        {
          id: "8",
          headline: "Where would you hang this painting? ",
          subline: "In the...",
          category: 0,
          choices: [
            {
              value: "Bathroom",
              addition: "A",
              score: 3
            },
            {
              value: "Livingroom",
              addition: "B",
              score: 0
            },
            {
              value: "Bedroom",
              addition: "C",
              score: 6
            },
            {
              value: "Kitchen",
              addition: "D",
              score: -6
            },

            {
              value: "Nowhere",
              addition: "E",
              score: 6
            }
          ]
        },
        {
          id: "9",
          headline: "Which color do you think is predominant in this picture? ",
          subline: "Choose the right one ",
          category: 1,
          choices: [
            {
              value: "Color A",
              addition: "#040103",
              score: 4
            },
            {
              value: "Color B",
              addition: "#8d6e94",
              score: -5
            },
            {
              value: "Color C",
              addition: "#f1bd99",
              score: -2
            },
            {
              value: "Color D",
              addition: "#f7e1af",
              score: 3
            },
            {
              value: "Color E",
              addition: "#51407f",
              score: -2
            }
          ]
        }
      ]
    };
  },
  methods: {
    onExitClick() {
      this.$emit("exit");
    },
    calculateText(scoreMental, scoreCognitive) {
      if (scoreMental < -14) {
        this.mentalText =
          "Leaving your biological age aside, you certainly feel much younger in spirit than in real life. You meet your environment with open arms and curious eyes. New impulses, fresh impressions and perhaps also your pleasantly different view of things distinguish you. That's great! Stay as you are and embrace life as you probably do every day anyway. Carpe diem!";
      } else if (scoreMental < -5) {
        this.mentalText =
          "'You are getting old!' ... if something does not apply to you at all, it´s certainly this statement. Maybe you have children, maybe you´re working in a young environment, maybe it is just within your nature to look at and approach life around you with youthful ease. This makes you a cheerful person in whose presence people like to forget the seriousness and the stress of everyday life. Our tip: Stay as you are, you are an inspiring fountain of youth and expand the horizon of your fellow people again and again.";
      } else if (scoreMental < 6) {
        this.mentalText =
          "Professionally and privately, you have certainly made your way in life. You are standing with both feet right in the middle of it. This makes you a valued friend, partner and colleague, whose view on everyday life, inspires others. Your perception of the world is always a safe haven for others. Because one can rely on getting a thoughtful, authentic and above all realistic assessment and view of things from your side. Our tip: Just stay as you are, you are great! ";
      } else if (scoreMental < 15) {
        this.mentalText =
          "Do you ever find that when you're sitting around with friends or family, that you follow the conversations with an inner smile? This could be because you are emotionally and mentally ahead of others. You perceive your surroundings and what happens in life with the calmness and composure of an old soul at peace with itself. Surely you have already experienced, seen and learned a lot. This radiates a security and reliability for others, which is appreciated. Nothing knocks you down easily. Go ahead!";
      } else {
        this.mentalText =
          "You radiate a very wise, mature spirit for your age. This is a quality that people in your social and professional environment will certainly appreciate in you. Friends and acquaintances will come to you often to get advice in matters of life from you. Due to your level-headed and far-sighted nature, you are able to look at things from all sides and discuss them objectively. For others you are the ideal sparring partner to solve difficult questions and conflicts, to direct the attention to the essential and to open their eyes for new perspectives. You are a beautiful mind.";
      }

      if (scoreCognitive < -19) {
        this.cognitiveText =
          "Are you that Dr. Kawashima from brain jogging or are you just a passionate Sudoku player? You seem to be an above-average smart brain. Keep at it and continue to keep yourself youthful in spirit. Brain training also sharpens your senses and your perception. In your UCHRONIA, life is one voyage of discovery and various exciting impressions.";
      } else if (scoreCognitive < -7) {
        this.cognitiveText =
          "You are a pretty smart brain! With your cleverness you are not only ahead of your actual age but often a good deal ahead of others. You should definitely keep and train your mental abilities. Your curiosity and willingness to try new things are the natural fuel for a sharp mind. Stay as you are, keep your mind and body healthy, then you will be able to perceive and enjoy the world in all its facets and beauty in all stages of age. ";
      } else if (scoreCognitive < 8) {
        this.cognitiveText =
          "You are true to yourself and that is good! Your mental fitness fully corresponds to your actual age so everything is in the green area. Which does not mean that you should rest on it now since the mental agility and the natural, coginitive efficiency of the human brain decreases with age. On the contrary, we become more knowledgeable and far-sighted but, generally speaking,  our brain simply becomes a bit more comfortable over time. To stay interested and curious is like a workout for the brain. Our tip: train your brain, it keeps you young and mentally fit.";
      } else if (scoreCognitive < 20) {
        this.cognitiveText =
          "Apparently our quiz says that you seem a bit 'older' than your actual age, but don't let that demotivate you. It is quite normal to sometimes feel more awake and fitter in mind and sometimes less, depending on your mood of the day. Stress in the job and everyday life are a lot of work for the brain. So, it´s only natural that sometimes, our mind is tired too. That's why our tip is to let your mind wander more often. This way you are not only jogging on both legs but also with your brain. That keeps you young and mentally fit!";
      } else {
        this.cognitiveText =
          "No matter what our quiz says, you are not 'too old' for it! ;-). You are never too old for anything, remember that! Maybe you have a lot on your mind right now? Work, job, family.. all that is work for the brain. Sometimes there is simply no room to quickly focus on a complex task. That's why children are so much better at memory games. Because they have their heads free and can focus on one thing with all their senses. Make sure to do something completely different from time to time. This keeps you young and your mind fresh!";
      }
    },
    calculateScore() {
      const age = Math.min(Math.max(this.answers[0].age, 25), 87);
      this.age = age;

      const score0 = this.scores[0];
      const score1 = this.scores[1];

      const ageMental = (age * 2 + score0) / 2;
      const ageCognitive = (age * 2 + score1) / 2;

      this.calculateText(score0, score1);

      this.ageMental = Math.min(Math.max(18, Math.round(ageMental)), 87);
      this.ageCognitive = Math.min(Math.max(18, Math.round(ageCognitive)), 87);
    },
    onRestartClick() {
      this.step = 0;
      this.scores = [0, 0];
      this.buttonDisabled = true;
      this.currentAnwerIndex = undefined;
      this.ageValid = false;
      this.testComplete = false;
    },
    setAnswer(item, index) {
      this.currentAnwerIndex = index;

      const cat = this.contents[this.step].category;

      item.category = cat;
      this.answers[this.step] = item;

      this.buttonDisabled = false;
      this.scores[cat] += item.score;
    },
    checkAge() {
      const val = this.$refs.age_input.value;
      if (val > 12 && val < 100) {
        this.ageValid = true;
        this.buttonDisabled = false;
      } else {
        this.ageValid = false;
        this.buttonDisabled = true;
      }

      this.answers[0] = {
        age: val
      };
    },
    onNextClick() {
      this.step += 1;
      if (this.step >= this.contents.length - 1) {
        this.testComplete = true;
        this.calculateScore();
        return;
      }

      this.buttonDisabled = true;
      this.currentAnwerIndex = undefined;
    },
    onPreviousClick() {
      this.step -= 1;
    },
    onResize() {},
    init() {
      this.onResize();
    }
  },
  watch: {
    step() {
      gsap.set(this.$refs.steps, { autoAlpha: 0 });
      gsap.to(this.$refs.steps, { delay: 0.1, autoAlpha: 1 });

      gsap.to(this.$refs.fillrect, {
        transformOrigin: "top left",
        scaleX: this.step / (this.contents.length - 1)
      });
    },
    stageWidth() {
      this.onResize();
    }
  },
  computed: {
    currentContent() {
      return this.contents[this.step];
    },
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {},

  mounted() {
    gsap.from(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1.2,
      alpha: 0
    });
  }
};
</script>

<style scoped lang="scss">
</style>